.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    max-width: 800px; /* Adjust the max-width as needed */
    margin: 0 auto; /* This centers the grid */
  }
  
  .image-item {
    /* Style individual image containers */
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    /* Adjust styling as needed */
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

  