.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 90%; /* Limit the width */
    max-height: 90%; /* Limit the height */
    overflow: auto; /* Add scroll for overflow */
    position: relative;
  }
  
  
  .modal-content img {
    max-width: 100%; /* Image width will not exceed the container width */
    max-height: 80vh; /* Limit image height based on the viewport height */
    display: block; /* Remove extra space below the image */
    margin: 0 auto; /* Center the image horizontally */
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #000; /* Or any color that contrasts well with the modal background */
  }

  .modal-arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  
  .modal-arrow.left {
    left: 10px;
  }
  
  .modal-arrow.right {
    right: 10px;
  }
  