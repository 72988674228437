.chat-container {
    display: flex;
    flex-direction: column;
    height: 450px;
    padding: 10px;
    background-color: rgb(159, 194, 217);
    border-radius: 50px;
    padding: 40px;
}

.chatContainer {
    height: 500px; /* 100% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column; /* Newest messages at the bottom */
  }

.messages-container {
    flex-grow: 1;
    overflow-y: auto;
}

.message {
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
    background-color: white;
}

.message.user {
    margin-left: auto;
    background-color: blue;
    color: white;
}

.input-container {
    display: flex;
    margin-top: 10px;
}

input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: blue;
    color: white;
    cursor: pointer;
}

.spinner {
    border: 4px solid rgba(0,0,0,0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}