.container {
    display: flex;
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Full height of the viewport */
  }
  
  .content {
    max-width: 900px; /* Maximum width */
    width: 100%; /* Full width */
    padding-top: 48px;
    /* Add padding or other styles as needed */
  }
  